<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>充电客户>客户车辆列表
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :selectable="true"
        :selectOnIndeterminate="false"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item != null)"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button
                :type="btn.type"
                v-if="i != 0 && btn.enabled()"
                :key="i"
                @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
              <el-dropdown @command="handleCommand" v-if="i == 0 && btn.enabled()" :key="i">
                <ykc-button :type="btn.type" v-if="btn.enabled()">
                  {{ btn.text }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </ykc-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('batchAdd')"
                    v-if="code('customer:vehicle:batchadd')">
                    批量新增
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="beforeHandleCommand('batchDelete')"
                    v-if="code('customer:vehicle:batchdelete')">
                    批量删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          ref="addOrEdit"
          :id="id"
          :editType="editType"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import { offlineExport, code } from '@/utils';
  import { customerOrg, customerVehicle } from '@/service/apis';

  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        id: null,
        editType: 'add',
        drawerType: '',
        maxIndex: 2,
        showDrawer: false,
        orgList: [],
        selectIds: [],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        pageButtons: [
          {
            id: '0',
            text: '批量处理',
            type: 'plain',
            enabled: () =>
              code('customer:vehicle:batchadd') || code('customer:vehicle:batchdelete'),
            handleClick: () => {
              this.doImportFile();
            },
          },
          {
            id: '1',
            text: '新增',
            type: 'plain',
            enabled: () => code('customer:vehicle:add'),
            handleClick: () => {
              this.add();
            },
          },
          {
            id: '2',
            text: '导出',
            type: 'plain',
            enabled: () => code('customer:vehicle:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: '客户车辆',
        tableColumns: [
          { label: '车牌号码', prop: 'plateNumber', minWidth: '100px' },
          { label: '车辆VIN', prop: 'vinCode', minWidth: '150px' },
          { label: '归属机构', prop: 'belongToName', minWidth: '150px' },
          { label: '主机构', prop: 'mainOrgName', minWidth: '150px' },
          { label: '关联用户账号', prop: 'userAccount', minWidth: '150px' },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('customer:vehicle:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.id = row.id;
                    this.editType = 'edit';
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                    this.showAddDrawer = true;
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('customer:vehicle:bind') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.editType = 'bind';
                    this.id = row.id;
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                    this.showAddDrawer = true;
                  }}>
                  绑定账号
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          id: '',
          carType: '2', // 1 c 2 客户车辆 3 子公司车辆
          plateNumber: '',
          vinCode: '',
          userPhone: '',
          belongToId: null,
          mainOrgId: null,
          userAccount: '',
        },
        configSet: {
          label: 'name',
          value: 'id',
        },
      };
    },
    created() {
      this.searchParams.userAccount = this.$route.query.userAccount;
      this.searchTableList();
      this.queryMainOrgWithOutZdlList();
      this.queryMainOrgContainZdlList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量新增';
        }
        // eslint-disable-next-line no-nested-ternary
        return `车辆${
          // eslint-disable-next-line no-nested-ternary
          this.editType === 'edit' ? '编辑' : this.editType === 'bind' ? '绑定' : '新增'
        }`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'plateNumber',
            label: '车牌号',
            placeholder: '请输入车牌号',
          },
          {
            comName: 'YkcInput',
            key: 'userPhone',
            label: '手机号',
            placeholder: '请输入手机号',
          },
          {
            comName: 'YkcInput',
            key: 'vinCode',
            label: '车辆VIN',
            placeholder: '请输入车辆VIN',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '关联用户账号',
            placeholder: '请输入关联用户账号',
          },
          {
            comName: 'YkcDropdown',
            key: 'belongToId',
            label: '归属机构',
            placeholder: '请选择归属机构',
            data: this.orgList,
          },
          {
            comName: 'YkcDropdown',
            key: 'mainOrgId',
            label: '主机构',
            data: this.mainOrgList,
            placeholder: '请选择主机构',
          },
        ];
      },
    },
    methods: {
      code,
      /** 勾选批量停用 */
      batchStop() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择要操作的记录');
          return;
        }
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          decs: `已选中 ${this.selectIds.length}条记录，是否确认全部停用？`,
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            customerVehicle.batchStop({ Ids: this.selectIds }).then(res => {
              console.log(JSON.stringify(res));
              this.$message({
                message: `停用成功`,
                type: 'success',
              });
              this.searchTableList();
            });
            done();
          },
        });
      },
      // 获取所属机构下拉数据
      queryMainOrgWithOutZdlList() {
        customerOrg.queryMainOrgWithOutZdlList({}).then(res => {
          this.orgList = res;
        });
      },
      // 获取主机构下拉数据
      queryMainOrgContainZdlList() {
        customerOrg.queryMainOrgList({}).then(res => {
          this.mainOrgList = res;
        });
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
      },
      add() {
        this.showAddDrawer = true;
        this.editType = 'add';
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /**
       * 动态设置Dropdown的command
       */
      beforeHandleCommand(flag) {
        // flag为传递的参数
        return {
          flag,
        };
      },
      handleCommand(e) {
        console.log(JSON.stringify(e));
        if (e.flag === 'batchAdd') {
          this.doImportFile();
        } else if (e.flag === 'batchDelete') {
          this.batchDelCarByIds();
        }
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 0;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        customerVehicle
          .list({
            ...this.pageInfo,
            ...this.searchParams,
            carType: '2', // 1 c 2 客户车辆 3 子公司车辆
          })
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total;
          });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'car',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.editType = 'edit';
        this.showDrawer = true;
      },
      // 批量删除ids
      batchDelCarByIds() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
        } else {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '确认批量删除吗？',
            onCancel: done => {
              console.log(done);
              done();
            },
            onConfirm: done => {
              console.log(done);
              customerOrg.batchDelCarByIds({ ids: this.selectIds }).then(res => {
                console.log(JSON.stringify(res));
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.searchTableList();
                this.selectIds = [];
              });
              done();
            },
          });
        }
      },
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.editType !== 'add') {
              this.editType = 'add';
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {
          id: '',
          carType: '2',
          plateNumber: '',
          vinCode: '',
          userPhone: '',
          belongToName: '',
          mainOrgName: '',
          userAccount: '',
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
